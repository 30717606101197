import React, { useState } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { AiFillMinusCircle } from 'react-icons/ai'
import { AiFillPlusCircle } from 'react-icons/ai'

import Layout from '../layouts/layout'
import Seo from '../components/seo'
import BasicCard from '../components/UI/Card/BasicCard'
import HomeButton from '../components/UI/Buttons/HomeButton'
import GiveHeartIcon from '../assets/images/svg/give-heart.svg'
import ContactBGImage from '../assets/images/contact-bg.svg'
import InlineIcon from '../components/UI/InlineIcon'
import { CONTACT, URL, parseJson, BUTTON_COLOR, BUTTON_SIZE } from '../support'

const Contact = ({ path }) => {

  const [fontSize, setFontSize] = useState(18)

  const queriedStructuredDataConfigForContact = useStaticQuery(graphql`
    query structuredDataConfigForContactQuery {
      contentfulComponentStructuredDataConfig (
        page: { eq: "contact" }
      ) {
        page
        structuredDataConfig {
          internal {
            content
          }
        }
      }
    }
  `)
  const structuredData = parseJson(queriedStructuredDataConfigForContact.contentfulComponentStructuredDataConfig.structuredDataConfig?.internal?.content)
  return (
    <Layout>
      <Seo 
        title="Our Contact Details"
        description="Have a question about our Personal Loans or Investing? Contact us to find out more about great rates on a personal loan in Australia with SocietyOne."
        path={path}
        structuredData={structuredData}
      />
      <ContactWrapper>
        <div className="covid-banner flex-row">
          <div className="svg-wrapper">
            <GiveHeartIcon />
          </div>
          <div style={{ marginLeft: 15 }}>
            <strong>Update</strong>: Have you been
            impacted by the QLD and NSW floods? We’re here to help. Find out
            more
            <a className="underline" href="/hardship">
              here
            </a>
          </div>
        </div>
        <h1 className="text-center heading">Contact Us</h1>

        {/* First Card */}
        <BasicCard borderRadius="8px" background="#f4f5f6" boxShadow="none">
          <h2 className='difficultyCard'>Difficulty making repayments?</h2>
          <div className="hardship-text">
            We're taking extra measures to support our customers during these
            challenging times.
          </div>
          <InlineIcon iconName="PageOpen">
            <a className="underline" href="/hardship">
              SocietyOne Hardship Support
            </a>
          </InlineIcon>

          <InlineIcon iconName="Email" text={CONTACT.EMAIL.CUSTOMER_CARE} />

          <InlineIcon iconName="Phone" text={CONTACT.PHONE.SERVICE}>
            <div>Mon-Fri, 9am - 5pm AEST</div>
          </InlineIcon>
        </BasicCard>

        {/* Second Card */}
        <BasicCard borderRadius="20px" padding="40px 32px" margin="0 auto 80px">
          <h2>General Enquiries</h2>
          <hr className="solid" />
          <h3>Customer Support</h3>
          <InlineIcon
            iconName="Email"
            text={CONTACT.EMAIL.CUSTOMER_SERVICE}
          />
          <InlineIcon iconName="Phone" text={CONTACT.PHONE.SERVICE}>
            <div>Mon-Fri, 9am - 5pm AEST</div>
          </InlineIcon>          
          <InlineIcon iconName="POBox">
            <div className="po-box-text">
              G.P.O. Box 5175, Sydney, NSW 2001, Australia
            </div>
          </InlineIcon>
          <hr className="solid" />
          <h3>Request account statement</h3>
          <InlineIcon
            iconName="Email"
            text={CONTACT.EMAIL.CUSTOMER_SERVICE}
          />
          <hr className="solid" />
          <h3>Investor</h3>
          <div>
            SocietyOne Pty Ltd is a wholly owned subsidiary of MONEYME Pty Limited (ASX: MME). 
            <br />
            Please refer to 
            <a className='underline' href={URL.MME_INVESTOR_CENTRE}>
              MoneyMe - Investor Centre 
            </a> 
            for more information.
          </div>
          <hr className="solid" />
          <h3>Media</h3>
          <div>
            For any media enquiries, please contact 
            <a className="underline" href={`mailto:${CONTACT.EMAIL.MME_MEDIA}`}>
              media@moneyme.com.au
            </a>
          </div>
        </BasicCard>

        {/* Third Card */}
        <BasicCard borderRadius="20px" padding="40px 32px" margin="0 auto 80px">
          <div className="flex-row complaints-header">
            <h2 className="contact-sub">Complaints</h2>
            <div className="flex-row buttons">
              <HomeButton
                height={BUTTON_SIZE.HEIGHT_S}
                padding="8px"
                fontSize={BUTTON_SIZE.FONT_SIZE_S}
                background="#f5f5f5"
                backgroundHover={BUTTON_COLOR.GRAY_HOVER}
                color={BUTTON_COLOR.TEXT_COLOR_BLUE}
                text="Decrease text size"
                handleClick={() =>
                  fontSize > 13 ? setFontSize(fontSize - 1) : null
                }
              >
                <div className="svg-wrapper flex-col">
                  <AiFillMinusCircle />
                </div>
              </HomeButton>
              <div className="vertical-line"></div>
              <HomeButton
                height={BUTTON_SIZE.HEIGHT_S}
                padding="8px"
                fontSize={BUTTON_SIZE.FONT_SIZE_S}
                background="#f5f5f5"
                backgroundHover={BUTTON_COLOR.GRAY_HOVER}
                color={BUTTON_COLOR.TEXT_COLOR_BLUE}
                text="Increase text size"
                handleClick={() =>
                  fontSize < 29 ? setFontSize(fontSize + 1) : null
                }
              >
                <div className="svg-wrapper flex-col">
                  <AiFillPlusCircle />
                </div>
              </HomeButton>
            </div>
          </div>
          <hr className="solid" />

          {/* Change the font size dynamically on button click */}
          <div style={{ fontSize: `${fontSize}px` }}>
            <div>
              We hope that you will be delighted with our services, but if you
              do have a complaint please email us at
              <a
                className="underline"
                href={`mailto:${CONTACT.EMAIL.CUSTOMER_SERVICE}`}
              >
                {CONTACT.EMAIL.CUSTOMER_SERVICE}
              </a>{' '}
              or call us on{' '}
              <a className="underline" href={`tel:${CONTACT.PHONE.SERVICE}`}>
                {CONTACT.PHONE.SERVICE}
              </a>
              . If you are emailing us, please set out details of your complaint
              as clearly as you can. When we receive a complaint, we will
              attempt to resolve it promptly.
              <br />
              <br />
              We aim to resolve all complaints within 5 business days and if
              this is not possible we will keep you informed of our progress.
              <br />
              <br />
            </div>
            <InlineIcon
              iconName="Email"
              text={CONTACT.EMAIL.CUSTOMER_SERVICE}
            />
            <InlineIcon iconName="Phone" text={CONTACT.PHONE.SERVICE}>
              <div>Mon-Fri, 9am - 5pm AEST</div>
            </InlineIcon>
            <hr className="solid" />
            <div>
              If you are not satisfied with our response to your complaint, or
              if you consider that we have not responded to you in a timely
              manner, you may contact the Australian Financial Complaints
              Authority:
              <br />
              <br />
              Australian Financial Complaints Authority
              <br />
              <br />
            </div>
            <InlineIcon iconName="POBox">
              <div className="po-box-text">GPO Box 3, Melbourne VIC 3001</div>
            </InlineIcon>
            <InlineIcon iconName="Email" text={CONTACT.EMAIL.AFCA} />
            <InlineIcon iconName="Phone" text={CONTACT.PHONE.AFCA} />
            <div>
              You can obtain further details about our dispute resolution
              procedures on request.
            </div>
            <hr className="solid" />
            <InlineIcon
              iconName="DocDownload"
              text="View in Traditional Chinese"
              url="https://assets-global.website-files.com/5c5cb2d92662fe6cc52e780d/61513149a3c2eadd49bedeb0_Online%20Complaints%20Policy_Traditional%20Chinese.pdf"
            />
            <InlineIcon
              iconName="DocDownload"
              text="View in Simplified Chinese"
              url="https://assets-global.website-files.com/5c5cb2d92662fe6cc52e780d/61512f14f21fc9761b8b247c_Online%20Complaints%20Policy_Simplified%20Chinese.pdf"
            />
            <InlineIcon
              iconName="DocDownload"
              text="View in Arabic"
              url="https://assets-global.website-files.com/5c5cb2d92662fe6cc52e780d/61512f0d1e11a6fe52e61d26_Online%20Complaints%20Policy_Arabic.pdf"
            />
          </div>
        </BasicCard>

        {/* Fourth Card */}
        <BasicCard borderRadius="20px" padding="40px 32px" margin="0 auto 80px">
          <h2 className="contact-sub">Security</h2>
          <hr className="solid" />
          <div>
            <strong className="quicksand">
              Have you received an email or SMS that doesn't seem quite right?
            </strong>
            <br />
            <br />
            Online scams (also known as phishing) cost Australians millions of
            dollars each year. It can be difficult to identify a phishing email
            or SMS, as they often do a good job of imitating a legitimate
            company’s branding and language.
            <br />
            <br />
            Because of this, it’s important to…
            <br />
            <br />
            • Always check the sender’s email address. Is it from an email
            address ending in @societyone.com.au? If it isn’t, it’s not from us
            <br />
            <br />
            • Never click on a link in an email that you think looks suspicious
            Always report suspicious emails within your email application as
            soon as you receive them, then delete them afterwards
            <br />
            <br />
            • Be aware that scammers may also contact you by phone. In this case
            we recommend you hang up and call the company’s listed phone number
            to verify the call
            <br />
            <br />
            • Remember, we’ll never ask you for information such as bank login
            details by email or text message
            <br />
            <br />
            Have you received a suspicious SocietyOne email or SMS or are you
            concerned about the safety of your account? Please contact us as
            soon as possible on:
            <br />
            <br />
          </div>
          <InlineIcon iconName="Email" text={CONTACT.EMAIL.CUSTOMER_SERVICE} />
          <InlineIcon iconName="Phone" text={CONTACT.PHONE.SERVICE}>
            <div>Mon-Fri, 9am - 5pm AEST</div>
          </InlineIcon>
        </BasicCard>

        <div
          className="contact-bg"
          style={{
            backgroundImage: `url(${ContactBGImage})`,
            backgroundSize: 'cover',
            backgroundPosition: '100% 50%',
          }}
        ></div>
      </ContactWrapper>
    </Layout>
  )
}

const ContactWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 0 0 120px;
  font-size: 18px;
  .flex-row {
    align-items: center;
  }
  .heading {
    margin-top: 80px;
  }
  .hardship-text {
    margin-top: 12px;
    margin-bottom: 24px;
    font-size: 16px;
  }
  a {
    margin: 0 10px;
    &:hover {
      color: inherit;
    }
  }
  hr {
    margin: 32px 0;
  }
  h3 {
    margin-bottom: 24px;
  }
  .svg-wrapper {
    width: 25px;
  }
  svg {
    width: 100%;
    height: 100%;
  }
  .po-box-text {
    margin: 0 10px;
  }
  .contact-sub {
    font-size: 32px;
  }
  .contact-bg {
    display: block;
    position: absolute;
    left: 0;
    top: 820px;
    right: 0;
    bottom: auto;
    z-index: -1;
    padding-top: 390px;
  }
  .covid-banner {
    background-color: #474ed3;
    justify-content: center;
    color: var(--white);
    font-size: 16px;
    padding: 20px;
  }
  .complaints-header {
    justify-content: space-between;
    .svg-wrapper {
      margin-right: 8px;
      justify-content: center;
    }
    button {
      width: auto;
      height: auto;
      transition: background-color 0.2s;
    }
  }
  .vertical-line {
    width: 1px;
    height: 30px;
    margin: 0 10px;
    background-color: #d4dbe0;
  }
  .underline {
    overflow-wrap: anywhere;
  }
  .difficultyCard {
    font-size: 28px;
    @media screen and (max-width: 768px) {
      font-size: 22px;
    }
  }

  @media screen and (max-width: 991px) {
    .heading {
      color: var(--white);
    }
    max-width: 100vw;
    padding: 0 20px 40px;
    .covid-banner {
      margin: 0 -20px;
    }
    .contact-bg {
      background-color: var(--base-navy);
      top: 0;
      padding-top: 750px;
      background-image: none !important;
    }
    .complaints-header {
      h2 {
        margin-bottom: 20px;
      }
      flex-direction: column;
      align-items: start;
    }
  }
  @media screen and (max-width: 479px) {
    .buttons {
      flex-direction: column-reverse;
      align-items: start;
      button:nth-child(3) {
        margin: 0 0 16px 0;
      }
    }
    .vertical-line {
      display: none;
    }
    hr.solid {
      margin: 16px 0;
    }
  }
`

export default Contact
